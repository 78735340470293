<template>
	<div>
		<div class="modal-header">
			<div class="modal-title">
				<a-icon type="medicine-box" style="margin-right:10px" />
				<span>{{ this.isEdit ? l('Edit') : l('Create') }}</span>
			</div>
		</div>
		<div>
			<a-config-provider :locale="zh_CN">
				<a-row>
					<a-col :span="24">
						<a-form-item required :label-col="{ span: 7 }" :wrapper-col="{ span: 9 }" :label="l('Name')"
							class="body">
							<a-input v-model="entity.name" class="input"></a-input>
						</a-form-item>
					</a-col>
					<a-col :span="24">
						<a-form-item required :label-col="{ span: 7 }" :wrapper-col="{ span: 9 }"
							:label="l('layoutOrg.leveindex')" class="body">
							<a-select showSearch :filterOption="filterOption" style="width: 200px;text-align: left;"
								dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
								v-model="leveIndex" :placeholder="l('please_enter_the_level_name_to_search')"
								allow-clear>
								<a-select-option v-for="item in workProcessData" :key="item.levelIndex"
									@click="workProcessClassChange" :title="item.name">
									{{ item.name }}
								</a-select-option>
							</a-select>
						</a-form-item>
					</a-col>
					<a-col :span="24">
						<a-form-item :label-col="{ span: 7 }" :wrapper-col="{ span: 9 }" :label="l('pid')">
							<a-tree-select v-model="pId" style="width: 200px" :tree-data="treeDataList"
								:replace-fields="treeReplaceFields"
								:dropdown-style="{ maxHeight: '400px', overflow: 'auto', width: '300px' }"
								:placeholder="l('please_select_the_superior_node')" :dropdownMatchSelectWidth="false"
								allow-clear tree-default-expand-all>
							</a-tree-select>
						</a-form-item>
					</a-col>

					<a-col :span="24">
						<a-form-item :label-col="{ span: 7 }" :wrapper-col="{ span: 9 }" :label="l('统计归属部门')">
							<a-select showSearch style="width: 200px;text-align: left;"
								dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
								v-model="StatisticsOrg" :placeholder="l('请选择统计部门归属')" allow-clear
								optionFilterProp="label">
								<a-select-option v-for="item in StatisticsOrgList" :key="item" :label="item">
									{{ item }}
								</a-select-option>
							</a-select>
						</a-form-item>
					</a-col>


					<a-col :span="24">
						<a-form-item :label-col="{ span: 7 }" :wrapper-col="{ span: 4 }" :label="l('匿名可见')">
							<a-switch :checked-children="l('Yes')"
								:un-checked-children="l('No')" v-model="AnonymousVisible" />
						</a-form-item>
					</a-col>
				</a-row>
			</a-config-provider>
		</div>
		<div class="modal-footer" v-if="!isLook">
			<a-button @click="close">
				{{ l('Cancel') }}
			</a-button>
			<a-button :type="'primary'" @click="save">
				{{ l('Save') }}
			</a-button>
		</div>
	</div>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
import { LayoutOrgServiceProxy, LayoutLevelServiceProxy, SystemConfigServiceProxy } from '../../../../shared/service-proxies';

let _this;
export default {
	name: 'structure',
	mixins: [ModalComponentBase],
	components: {

	},
	created() {
		this.fullData(); // 模态框必须,填充数据到data字段
		this.LayoutOrgServiceProxy = new LayoutOrgServiceProxy(this.$apiUrl, this.$api);
		this.LayoutLevelServiceProxy = new LayoutLevelServiceProxy(this.$apiUrl, this.$api);
		this.SystemConfigServiceProxy = new SystemConfigServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		this.getData();
		this.getTreeData();
		this.getTree();
		this.getStatisticsOrgConfig();
	},
	data() {
		return {
			zh_CN,
			isEdit: false,//是否是编辑
			isLook: false,//是否是查看
			entity: [],
			pId: undefined,
			leveIndex: undefined,
			name: undefined,
			treeDataList: [],
			workProcessData: [],
			treeReplaceFields: {
				title: 'name',
				key: 'id',
				value: 'id'
			},
			isclick: true,
			StatisticsOrg: undefined,
			StatisticsOrgList: [],
			AnonymousVisible:true,
		}
	},
	methods: {

		//获取部门归属配置
		getStatisticsOrgConfig() {
			this.isLoading = true;
			this.SystemConfigServiceProxy.getStatisticsOrgConfig(

			).finally(() => {
				this.isLoading = false;

			}).then(res => {
				this.StatisticsOrgList = res.split(",");
				console.log(res);
			})
		},
		getData() {
			this.isLoading = true;
			this.LayoutOrgServiceProxy.getForEdit(
				this.id
			).finally(() => {
				this.isLoading = false;
			}).then(res => {
				console.log(res);
				this.entity = res;
				this.name = res.name;
				this.StatisticsOrg = res.statisticsOrg;
				if (this.entity.pId == 0) {
					this.pId = undefined;
				} else {
					this.pId = this.entity.pId;
				}
				if (this.entity.leveIndex == 0) {
					this.leveIndex = undefined;
				} else {
					this.leveIndex = res.leveIndex;
				}
				this.AnonymousVisible = res.anonymousVisible;
			})
		},
		//获取层级等级
		getTree() {
			this.isLoading = true;
			this.LayoutLevelServiceProxy.getPaged(
				undefined,
			)
				.finally(() => {
					this.isLoading = false;
				})
				.then((res) => {
					// console.log(res.items);
					this.workProcessData = res.items;
				})
		},
		//获取上级节点
		getTreeData() {
			this.isLoading = true;
			this.LayoutOrgServiceProxy.getAllData(
			)
				.finally(() => {
					this.isLoading = false;
				})
				.then((res) => {
					// console.log(res);
					this.treeDataList = JSON.parse(JSON.stringify(res));
				})
		},
		workProcessClassChange(value) {
			// console.log(value.key);
			this.entity.leveIndex = value.key;
			// this.getData();
		},
		filterOption(input, option) {
			return (
				option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
			);
		},
		save() {
			if (!this.entity.name) {
				abp.message.info('名称不能为空');
				return;
			}

			if (this.entity.leveIndex == 0 || !this.entity.leveIndex) {
				abp.message.info(this.l('The_hierarchy_cannot_be_empty'));
				return;
			}

			if (this.isEdit == true) {
				if (this.pId == this.id) {
					abp.message.info(this.l('You_cannot_choose_yourself_as_the_superior_node'));
					return;
				}
			}

			if (this.pId == undefined) {
				this.entity.pId = 0;
			} else {
				this.entity.pId = this.pId;
			}
			this.entity.statisticsOrg = this.StatisticsOrg;
			this.entity.anonymousVisible = this.AnonymousVisible;
			var layoutOrgEditDto = { layoutOrgEditDto: this.entity };
			console.log(layoutOrgEditDto);
			if (this.isclick) {
				this.isclick = false;
				this.LayoutOrgServiceProxy.createOrUpdate(
					layoutOrgEditDto
				).finally(() => {
					this.close()
				}).then(res => {
					this.isclick = true;
					this.success(true)
				})
			}
		},

	}
}
</script>

<style>
.input {
	width: 200px;
}

.body {
	margin-bottom: 10px;
}
</style>
